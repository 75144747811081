import {VueSlideToggle} from "vue-slide-toggle";

export default {
    name: "partner",
    props: {
        info: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            open: false,
        }
    },
    components: {
        VueSlideToggle
    },

    methods: {
        smoothScroll(el) {
            const myEl = document.getElementById(el)

            if (myEl === null) {
                this.$router.push({path: "/"}).then(() => {
                    const myEl = document.getElementById(el)
                    this.$smoothScroll({
                        scrollTo: myEl,
                        // hash: '#sampleHash'
                    })
                })
            } else {
                this.$smoothScroll({
                    scrollTo: myEl,
                    // hash: '#sampleHash'
                })
            }


        },
        getLocation(item) {
            this.eventHub.$emit('location', item)
            this.smoothScroll('map')

        }
    }

}
