<template>
<div class="description" v-html="info.description">

</div>
</template>

<script>
export default {
  props:{
    info:{
      type:Object,
      default:()=>{}
    }
  },
  name: "description"
}
</script>

<style lang="scss">
.description{
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #4F4F4F;
  margin-bottom: 30px;
  p{
    line-height: 24px;
  }
}
@media screen and (max-width: 760px){
  .description{
    font-size: 16px;
  }
}
</style>