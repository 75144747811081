<template>

  <div class="map" id="map">
    <iframe
        :src=selectedLocation
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2543.436712924909!2d30.43022581595114!3d50.3956982991893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4c96b47e216fd%3A0xf11b1c9418f75df6!2z0YPQuy4g0KjQtdCy0YfQtdC90LrQviwgMTAyLCDQmtC40LXQsiwgMDIwMDA!5e0!3m2!1sru!2sua!4v1638866353847!5m2!1sru!2sua"
    }
  },
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  created() {

    this.eventHub.$on('location', data => {
      this.selectedLocation = data.src
      console.log(this.selectedLocation, 'location');
    })
  },
  comments: {},
  name: "map"
}
</script>

<style lang="scss">
.map {
  width: 100%;
  margin-bottom: 55px;

  iframe {
    width: 100%;
    height: 660px;
  }
}
</style>
