<template>
  <div class="block-with__video">
    <div class="block-with__video-title">{{ info.title }}</div>
    <div class="block-with__video-description" v-html="info.description"></div>
    <div class="block-with__video-video">
      <video width="100%" height="600" controls="controls">
        <source :src="info.video" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "block-with-video",
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style lang="scss">
.block-with__video {
  margin-top: 55px;

  &-title {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    margin-bottom: 20px;
  }

  &-description {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.3;
    color: #4F4F4F;
    margin-bottom: 55px;

    ul {
      li {

      }
    }

    p {
      line-height: 1.3;
    }
  }
}
</style>