import {mapActions, mapGetters} from "vuex";
import pageWrap from '../page-wrap/index.vue'

export default {
    name: "page-main",
    components: {
        pageWrap
    },
    data() {
        return {}
    },
    watch: {
        '$route'() {
            if (!this.$route.query.type) {
                this.fetchAboutPage(this.$route.params.slug)
            } else if (this.$route.query.type === 'terms') {
                this.fetchTerm(this.$route.params.slug)
            } else if (this.$route.query.type === 'new') {
                this.fetchNew(this.$route.params.slug)
            }
        }
    },
    created() {
        if (!this.$route.query.type) {
            this.fetchAboutPage(this.$route.params.slug)
        } else if (this.$route.query.type === 'terms') {
            this.fetchTerm(this.$route.params.slug)
        } else if (this.$route.query.type === 'new') {
            this.fetchNew(this.$route.params.slug)
        }

    },
    computed: {
        ...mapGetters({
            page: 'pages/page',
            pagesLoading: 'pages/pagesLoading',
            headerMenu: 'setting/headerMenu'
        }),
        getCurrentTittle(){
            for (let key in this.headerMenu) {
                let item = this.headerMenu[key]

                for (let el in item) {
                    let child = item[el]

                    for (let elem in child) {
                        let subChild = child[elem]

                        if (subChild.slug === this.$route.params.slug) {
                            return item.title
                        }

                    }
                }
            }
        },

    },
    methods: {
        ...mapActions({
            fetchAboutPage: 'pages/GET_PAGE',
            fetchNew: 'pages/GET_NEW',
            fetchTerm: 'pages/GET_ONE_TERM',

        })
    }

}
