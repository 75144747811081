<template>
  <div class="title-with__description">
    <div class="title-with__description-title">{{ info.title }}</div>
    <div class="title-with__description-description" v-html="info.description"></div>
  </div>
</template>

<script>
export default {
  name: "title-with-description",
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style lang="scss">

.title-with__description {


  &-title {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #333333;

    margin-bottom: 20px;
  }


  &-description {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.3;
    color: #4F4F4F;
    margin-bottom: 55px;


    ul {
      li {
        //&:before {
        //  content: '';
        //  width: 5px;
        //  height: 5px;
        //  background-color: #000000;
        //  border-radius: 2px;
        //  display: inline-block;
        //  margin-bottom: 3px;
        //  margin-right: 10px;
        //}
      }
    }

    p {
      line-height: 1.3;
    }
  }
}

@media screen and (max-width: 700px) {
  .title-with__description-description {
    div{
      width: 100% !important;
    }
  }
}

</style>
