<template>
  <div class="before-after">
    <div class="before-after__wrap">
      <div class="before-after__product">
        <img :src="info.product.data.image" alt="">
      </div>
      <div class="before-after__content">
        <div class="before-after__content-title">{{ info.product.data.title }}</div>
        <div class="before-after__images" v-for="item in info.items.data" :key="item.id">
          <div class="before-after__item">
            <div class="before-after__item-img">
              <img :src="item.beforeImage" alt="">
            </div>
            <div class="before-after__item-text">
              {{ $t('before') }}
            </div>
          </div>
          <div class="before-after__item">
            <div class="before-after__item-img">
              <img :src="item.afterImage" alt="">
            </div>
            <div class="before-after__item-text">
              {{$t('after')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "before-after",
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style scoped lang="scss">
.before-after {
  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    &-title {
      font-weight: normal;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      margin-bottom: 50px;
      margin-left: 50px;
    }
  }

  &__images {
    display: flex;
  }

  &__product {
    width: 529px;
    height: 529px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__images {
    margin-left: 50px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &-text {
      margin-top: 15px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #000000;
    }

    &-img {
      width: 300px;
      height: 410px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 910px){
  .before-after__wrap{
    flex-direction: column;
  }
  .before-after__product{
    width: 100%;
    img{

    }
  }
  .before-after__images{
    width: 100%;
    justify-content: center;
    margin-left: 0;
  }
  .before-after__item-img{
    width: 100%;
    //width: 150px;
    //height: 205px;
  }
  .before-after__content{
    width: 100%;
    //width: 265px;
    //height: 265px;
  }
  .before-after__content-title{
    margin-top: 15px;
    margin-left: 0;
    text-align: center;
  }
}
@media screen and (max-width: 600px){
  .before-after__item-img{
    height: 250px;
  }
}
</style>