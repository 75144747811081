<template>
  <div class="page">
    <div class="container">
      <div class="page-wrap" v-if="info">
        <div class="page-title"
             v-if="!$route.query.hasOwnProperty('smallTitle') && $route.params.slug !== 'catalog-and-protocols' && $route.params.slug !== 'application-for-presentation' && $route.params.slug !== 'before-after' && $route.params.slug !== 'terms-of-cooperation' && $route.params.slug !== 'scientific-news'&& $route.params.slug !== 'patents'&& $route.params.slug !== 'innovative-ingredients' && $route.params.slug !== 'partners'"
             :class="{'mb':$route.params.slug === 'partners'}">
        </div>
        <div class="page-title__second"
             :class="{'big':$route.query.hasOwnProperty('smallTitle') || $route.params.slug === 'catalog-and-protocols' || $route.params.slug === 'application-for-presentation' || $route.params.slug === 'before-after' || $route.params.slug === 'terms-of-cooperation' || $route.params.slug === 'scientific-news'|| $route.params.slug === 'patents'|| $route.params.slug === 'innovative-ingredients'|| $route.params.slug === 'partners'}">
          {{ info.title }}
        </div>
        <div v-if="info.pageContents.data.length">
          <static-content>
            <div v-for="block in info.pageContents.data">
              <block-with-video v-if="block.type === 'block_with_video'" :info="block"/>
              <title-with-description v-if="block.type === 'title_with_description'" :info="block"/>
              <phrase v-if="block.type === 'phrase'" :info="block"/>
              <block-with-image v-if="block.type === 'block_with_image'" :info="block"/>
              <mapGeolocation v-if="block.type === 'map'" :info="block"/>
              <description v-if="block.type === 'description'" :info="block"/>
              <block-with-image-with-button v-if="block.type === 'block_with_image_with_button'" :info="block"/>
              <banner v-if="block.type === 'banner'" :info="block"/>
            </div>
          </static-content>
        </div>
        <div class="partners" v-if="info.partners">
          <div class="page-partner" v-for="(partner,index) in info.partners.data" :key="index">
            <partner :info="partner"/>
          </div>
        </div>
        <div class="certificate-list" v-if="info.qualityCertificates">
          <div class="page-certificate" v-for="certificate in certificatesList" :key="certificate.id">
            <certificate :info="certificate"/>
          </div>
        </div>
        <div class="patents" v-if="info.patents">
          <div class="page-patent" v-for="patent in patentsList" :key="patent.id">
            <patent :info="patent"/>
          </div>
        </div>
        <div class="innovatives" v-if="info.innovativeIngredients">
          <router-link
              :to="$localize({ name: 'ingredient-page',params:{slugPage: info.slug, slugIngredient: ingredient.id},query:{title:$route.query.title} })"
              class="page-innovative"
              v-for="ingredient in ingredientsList"
              :key="ingredient.id">
            <ingredients :info="ingredient"/>
          </router-link>
        </div>
        <div class="news" v-if="$route.params.slug === 'scientific-news'">
          <div class="page-new" v-for="(item,index) in newsList" :key="item.id">
            <static-content>
              <one-new :info="item" :oposit="index % 2 === 0"/>
            </static-content>
          </div>
        </div>
        <div class="innovatives-load" v-if="info.innopageCertificatevativeIngredients">
          <main-button :outline="true" v-show="info.innovativeIngredients.data.length !== ingredientsList.length"
                       @change-event="loadMoreIngredients(3)" :label="$t('loadMore')"></main-button>
        </div>
        <div class="certificate-load" v-if="info.qualityCertificates">
          <main-button :outline="true" v-show="info.qualityCertificates.data.length !== certificatesList.length"
                       @change-event="loadMoreCertificates(3)" :label="$t('loadMore')"></main-button>
        </div>
        <div class="patent-load" v-if="info.patents">
          <main-button :outline="true" v-show="info.patents.data.length !== patentsList.length"
                       @change-event="loadMorePatents(3)" :label="$t('loadMore')"></main-button>
        </div>
        <div class="news-load" v-if="news">
          <main-button :outline="true" v-show="news.length !== newsList.length"
                       @change-event="loadMoreNews(3)" :label="$t('loadMore')"></main-button>
        </div>

        <div class="before-after" v-if="$route.params.slug === 'before-after'">
          <div class="page-before__after" v-for="item in beforeAfter" :key="item.id">
            <before-after :info="item"/>
          </div>
        </div>
        <div class="protocols" v-if="$route.params.slug === 'catalog-and-protocols' && info.catalogAndProtocols">
          <div class="page-protocol" v-for="item in info.catalogAndProtocols.data" :key="item.id">
            <protocol :info="item"/>
          </div>
        </div>
        <div class="terms" v-if="$route.params.slug === 'terms-of-cooperation'">
          <div class="page-term" v-for="(item,index) in cooperationTerms" :key="item.id">
            <terms :info="item" :oposit="index % 2 !== 0"/>
          </div>
        </div>
        <div class="page-button" v-if="$route.params.slug === 'application-for-presentation'">
          <main-button :label="$t('sendRequest')" @change-event="changeApplicationFormPopup(true)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blockWithImage from '../components/block-with-image/index.vue'
import titleWithDescription from '../components/title-with-description/index.vue'
import phrase from '../components/phrase/index.vue'
import blockWithVideo from '../components/block-with-video/index.vue'
import mapGeolocation from '../components/map/index.vue'
import certificate from '../components/certificate/index.vue'
import patent from '../components/patent/index.vue'
import ingredients from '../components/ingredient/index.vue'
import oneNew from '../components/new/index.vue'
import beforeAfter from '../components/before-after/index.vue'
import protocol from '../components/protocol/index.vue'
import terms from '../components/terms/index.vue'
import description from '../components/description/index.vue'
import partner from '../components/partner/index.vue'
import blockWithImageWithButton from '../components/block-with-image-with-button/index.vue'
import banner from '../components/banner/index.vue'
import staticContent from '@/components/static-content/index.vue'

import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "page-wrap",
  components: {
    banner,
    blockWithImage,
    titleWithDescription,
    phrase,
    blockWithVideo,
    mapGeolocation,
    certificate,
    patent,
    ingredients,
    oneNew,
    beforeAfter,
    protocol,
    terms,
    description,
    partner,
    blockWithImageWithButton,
    staticContent
  },
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      ingredientSkip: 4,
      certificatesSkip: 4,
      patentsSkip: 4,
      newsSkip: 2,
    }
  },
  computed: {
    ...mapGetters({
      news: 'pages/news',
      beforeAfter: 'pages/beforeAfter',
      cooperationTerms: 'pages/cooperationTerms',
      headerMenu: 'setting/headerMenu'
    }),
    getCurrentTittle() {
      for (let key in this.headerMenu) {
        let item = this.headerMenu[key]

        for (let el in item) {
          let child = item[el]

          for (let elem in child) {
            let subChild = child[elem]

            if (subChild.slug === this.$route.params.slug) {
              return item.title
            }

          }
        }
      }
    },
    ingredientsList() {
      let arr = []
      for (let i in this.info.innovativeIngredients.data) {
        if (arr.length === this.ingredientSkip || this.info.innovativeIngredients.data[i] === undefined) {
          break
        }
        arr.push(this.info.innovativeIngredients.data[i])
      }
      return arr
    },
    certificatesList() {
      let arr = []
      for (let i in this.info.qualityCertificates.data) {
        if (arr.length === this.certificatesSkip || this.info.qualityCertificates.data[i] === undefined) {
          break
        }
        arr.push(this.info.qualityCertificates.data[i])
      }
      return arr
    },
    patentsList() {
      let arr = []
      for (let i in this.info.patents.data) {
        if (arr.length === this.patentsSkip || this.info.patents.data[i] === undefined) {
          break
        }
        arr.push(this.info.patents.data[i])
      }
      return arr
    },
    newsList() {
      let arr = []
      for (let i in this.news) {
        if (arr.length === this.newsSkip || this.news[i] === undefined) {
          break
        }
        arr.push(this.news[i])
      }
      return arr
    },

  },
  watch: {
    '$route'() {
      if (this.$route.params.slug === 'scientific-news') {
        this.fetchNews()
      } else if (this.$route.params.slug === 'before-after') {
        this.fetchBeforeAfter()
      } else if (this.$route.params.slug === 'terms-of-cooperation') {
        this.fetchCooperationTerms()
      }
    }
  },
  created() {
    if (this.$route.params.slug === 'scientific-news') {
      this.fetchNews()
    } else if (this.$route.params.slug === 'before-after') {
      this.fetchBeforeAfter()
    } else if (this.$route.params.slug === 'terms-of-cooperation') {
      this.fetchCooperationTerms()
    }

  },
  methods: {
    ...mapActions({
      fetchNews: 'pages/GET_NEWS',
      fetchBeforeAfter: 'pages/BEFORE_AFTER',
      fetchCooperationTerms: 'pages/COOPERATION_TERMS',
    }),
    ...mapMutations({
      changeApplicationFormPopup: `popups/CHANGE_APPLICATION_FORM_POPUP`,
    }),
    loadMoreIngredients(skip) {
      this.ingredientSkip += skip
    },
    loadMoreCertificates(skip) {
      this.certificatesSkip += skip
    },
    loadMorePatents(skip) {
      this.patentsSkip += skip
    },
    loadMoreNews(skip) {
      this.newsSkip += skip
    },
  }
}
</script>

<style scoped lang="scss">

.page {

  ul {
    padding: 20px 0 20px 20px;
  }

  ol {
    padding: 20px 0 20px 20px;
  }

  .certificate-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -60px;
    margin-right: -60px;
    margin-top: -60px;
  }

  &-title {
    font-weight: normal;
    font-size: 40px;
    line-height: 49px;
    text-transform: uppercase;
    color: #000000;
    margin-top: 25px;

    &.mb {
      margin-bottom: 60px;
    }

    &__second {
      font-weight: normal;
      font-size: 40px;
      line-height: 40px;
      color: #4F4F4F;
      margin-bottom: 49px;

      &.big {
        font-weight: normal;
        font-size: 40px;
        line-height: 49px;
        text-transform: uppercase;
        color: #000000;
      }
    }
  }

  .certificate, .patent, .news {
    &-load {
      display: flex;
      justify-content: center;
      margin-top: 55px;

      button {
        width: 200px;
        height: 50px;
      }
    }
  }

  &-certificate {
    width: calc(50% - 120px);
    margin: 0px 60px;

  }

  &-patent {
    margin-bottom: 55px;
  }

  .innovatives {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: 100px;
    grid-auto-flow: dense;

    &-load {
      display: flex;
      justify-content: center;
      margin-top: 55px;

      button {
        width: 200px;
        height: 50px;
      }
    }
  }

  &-new {
    margin-bottom: 55px;
  }

  &-innovative {
    background-color: grey;
    grid-row: span 2;
    cursor: pointer;

    &:nth-child(5n - 4) {
      grid-row: span 4
    }

    &:nth-child(5n - 3) {
      grid-row: span 3;
    }

    &:nth-child(5n - 2) {
      grid-row: span 3;
    }

    &:nth-child(5n - 1) {
      grid-row: span 4;
    }

    &:nth-child(5n) {
      grid-row: span 3;
    }
  }

  &-before__after {
    margin-bottom: 100px;
  }

  &-button {
    display: flex;
    justify-content: center;
    height: 43px;

    button {
      max-width: 250px;
    } max-width: 1050px;
    width: 100%;
  }

  &-term {
    margin-bottom: 55px;
  }

  .protocols {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: -25px;
    margin-right: -25px;
    justify-content: flex-start;
  }

  &-protocol {
    margin: 45px;
    margin-top: 0;
    width: calc(25% - 90px);
  }
}

@media screen and (max-width: 1600px) {
  //.page .container {
  //  max-width: 1050px;
  //  width: 100%;
  //}
  .page-protocol {
    width: calc(30% - 45px);
    margin: 39px;
    margin-top: 0;
  }
}

@media screen and (max-width: 1140px) {
  .page-protocol {
    margin-top: 0;
    width: calc(45% - 45px);
    margin: 40px;
  }
  .page-certificate {
    width: 100%;
  }
  .page-title, .page-title__second.big {
    font-size: 32px;
  }
  .page-title__second {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 900px) {
  .page-protocol {
    width: calc(100% - 45px);
    margin: 40px;
  }
}

@media screen and (max-width: 660px) {

  .protocol-download {
    bottom: 20%;
    right: -40px;
  }
  .page-protocol {
    width: 100%;
    //margin: 0;
    margin-bottom: 40px;
  }
  .page .protocols {
    margin-left: 0;
    margin-right: 0;
  }
  .page-title {
    font-size: 24px;
  }
  .page-innovative {
    height: 350px;
  }
  .page .innovatives {
    display: flex;
    flex-direction: column;
  }
}
</style>
