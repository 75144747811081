<template>
  <div class="block-with-image-with-button">
    <div class="block-with-image-with-button-wrap">
      <div class="block-with-image-with-button-left">
        <div class="block-with-image-with-button-title">{{ info.title }}</div>
        <div class="block-with-image-with-button-description" v-html="info.description"></div>
        <div class="block-with-image-with-button-button">
          <main-button @change-event="redirect(info.buttonLink)" :label="info.buttonText"/>
        </div>
      </div>
      <div class="block-with-image-with-button-right">
        <img :src="info.image" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "block-with-image-with-button",
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    redirect(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style lang="scss">
.block-with-image-with-button {
  margin-bottom: 35px;

  &-wrap {
    display: flex;
  }

  &-left, &-right {
    width: 50%;
  }

  &-left {
    margin-right: -30px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-right {
    height: 700px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-title {
    font-weight: normal;
    font-size: 40px;
    line-height: 72px;
    color: #333333;
    margin-bottom: 32px;
  }

  &-description {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #4F4F4F;
    background: #F2F2F2;
    padding: 32px 34px;
    z-index: 3;
    margin-bottom: 32px;

    ul {
      li {
        //&:before {
        //  content: '';
        //  width: 5px;
        //  height: 5px;
        //  background-color: #000000;
        //  border-radius: 2px;
        //  display: inline-block;
        //  margin-bottom: 3px;
        //  margin-right: 10px;
        //}
      }
    }
  }

  &-button {
    height: 45px;
    width: 350px;

  }
}

@media screen and (max-width: 930px) {
  .block-with-image-with-button-button{
    width: 100%;
  }
  .block-with-image-with-button-title {
    font-size: 32px;
    line-height: 1.2;
  }
  .block-with-image-with-button-description {
    padding: 20px;
    font-size: 14px;
  }
  .block-with-image-with-button-wrap {
    flex-direction: column;
  }
  .block-with-image-with-button-right {
    width: 100%;
    height: 400px;
    order: 1;
  }
  .block-with-image-with-button-left {
    margin-top: 30px;
    width: 100%;
    order: 2;
  }
}
</style>
