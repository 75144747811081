<template>
  <div class="block-with__image">
    <div class="block-with__image-title">{{ info.title }}</div>
    <div class="block-with__image-description" v-html="info.description">
    </div>
    <div class="block-with__image-img">
      <img :src="info.image" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "block-with__image",
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style scoped lang="scss">
.block-with__image {
  &-title {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    margin-top: 60px;
  }

  &-description {
    margin-top: 25px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;

    p {
      line-height: 24px;
    }
  }

  &-img {
    margin-top: 55px;
    margin-bottom: 55px;
    width: 100%;
    height: 450px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1000px) {
  .block-with__image-title {
    margin-top: 40px;
  }
  .block-with__image-img {
    margin-top: 20px;
    margin-bottom: 0;
    height: auto;

    img {
      object-fit: contain;
    }
  }
}
</style>
