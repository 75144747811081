<template>
  <div class="certificate">
    <div class="certificate-wrap">
      <a :href="info.file" target="_blank" class="certificate-image">
        <img :src="info.pdfPreview ? info.pdfPreview : info.file" alt="">
        <div class="certificate-image__description" v-if="info.description.length">
          {{ info.description }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style scoped lang="scss">
.certificate {
  &-image {
    display: block;
    width: 100%;
    height: 700px;
    position: relative;
    text-decoration: none;

    &__description {
      position: absolute;
      background: #F2F2F2;
      z-index: 2;
      bottom: -50px;
      width: 470px;
      right: -100px;
      padding: 27px 17px 27px 40px;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #4F4F4F;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 1140px) {
  .certificate-image {
    height: 500px;
    //height: auto;
    img {
      object-fit: contain;
    }

  }
  .certificate-image__description {
    right: 0;
    width: 100%;
    padding: 15px;
    font-size: 16px;
  }
}
</style>