<template>
  <div class="terms">
    <div class="terms-wrap" :class="{'oposit': oposit}">
      <div class="terms-img">
        <img :src="info.image" alt="">
      </div>
      <div class="terms-text">
        <div class="terms-title">{{ info.title }}</div>
        <div class="terms-button">
          <main-button :label="$t('readMore')" @change-event="redirect(info.slug)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "terms",
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    oposit: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    redirect(slug) {
      this.$router.push({
        name: 'page',
        params: {
          slug: slug
        },
        query: {
          type: 'terms',
          title: this.$route.query.title,
          smallTitle: false
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="scss">
.terms {
  &-wrap {
    display: flex;
    align-items: center;

    &.oposit {
      .terms-text {
        order: 1;
      }

      .terms-title {
        z-index: 2;
        margin-left: 0;
        margin-right: -25px;
      }

      .terms-img {
        order: 2;
        z-index: -1;
      }

      .terms-button {
        margin-left: 0;
      }
    }
  }

  &-img {
    width: 60%;
    height: 365px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-text {

    width: 40%;
  }

  &-title {
    margin-left: -25px;
    padding: 47px 58px;
    background: #F2F2F2;

    font-weight: normal;
    font-size: 40px;
    line-height: 49px;
    text-transform: uppercase;
    color: #000000;

  }

  &-button {
    height: 40px;
    margin-left: 30px;
    margin-top: 30px;
    max-width: 250px;
  }
}

@media screen and (max-width: 920px) {
  .terms {
    .terms-title {
      font-size: 28px;
      line-height: 1.2;
      margin-left: 0;
      padding: 25px;
    }

    &-wrap {
      flex-direction: column;

      &.oposit {
        .terms-text {
          order: 2;
        }

        .terms-title {
          z-index: 2;
          margin-left: 0;
          margin-right: 0;
        }

        .terms-img {
          order: 1;
          z-index: -1;
        }

        .terms-button {
          margin-left: 0;
        }
      }
    }

    &-img {
      width: 100%;
    }

    &-text {
      width: 100%;
    }

    &-button {
      margin-left: 0;
    }
  }

}

@media screen and (max-width: 520px) {
  .terms {
    .terms-title {
      font-size: 24px;
      line-height: 1.2;
      margin-left: 0;
      padding: 25px;
    }
  }
}
</style>