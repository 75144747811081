<template>
  <div class="phrase" v-if="info.type === 'phrase'">
    <div class="phrase-text">{{ info.description }}</div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style scoped lang="scss">
.phrase {
  padding-left: 40px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;

  &-text {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #4F4F4F;
  }
}
</style>